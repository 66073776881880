import axios from "axios";
import router from "../router";
import { hexMD5 } from "./md5.js";
import { Message } from "element-ui";

// axios.defaults.baseURL = 'http://apimgr_dev.11oi.com/'; // 请求地址
axios.defaults.baseURL = ""; // 请求地址
axios.defaults.timeout = 60000; // 请求超时限制

//开始请求设置，发起请求拦截
axios.interceptors.request.use(
  (config) => {
    let signatureObj = signature();
    let token = localStorage.getItem("token");
    let publicParams = {
      app: 30, //int 端 10=商学院微信小程序 11=商学院H5 20=会员端微信小程序 21=会员端抖音小程序 22=会员端H5 30=管理后台
      proname: "penmamaMgr", //string 项目名称 tech=技术管理后台 operate=运营管理后台 finance=财务管理后台 community=社群后台
      token: token, //String 登录令牌，有的话一定要传，登录认证的接口必传；
      version: 2020070601, //long 程序版本号，程序内部的版本号，格式：yyyyMMdd批次，如：2020031801；
    };
    // 获取oss签名
    if (config.url != "http://apimgr_dev.11oi.com/model/oss/policy?business=orderFreeAddYinDao&proname=oss") {
      config.params = Object.assign({}, config.params, publicParams, signatureObj);
    }
    return config;
  },
  (error) => {
    // 请求 错误处理
    return Promise.reject(error);
  }
);

//签名
function signature() {
  let api_priv_key = "1FWbxxnb4sJ8$@R!E5rx";
  let num = new Date().getTime();
  let str = String(num - 1539328140705);
  let mp = "aRHAbMQYiO";
  let ranNum = "";
  for (let i = 0; i < str.length; i++) {
    let n = str.charAt(i);
    ranNum += mp.charAt(n);
  }
  let sign = hexMD5(api_priv_key + num);

  return {
    ranNum: ranNum,
    sign: sign,
  };
}

// respone 响应拦截器
axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    //这里根据后台返回来设置
    if (res.code === 0) {
      // 响应成功
      return response.data;
    } else if (res.code == 7000) {
      localStorage.removeItem("token");
      router.push("/login");
      Message.error(res.msg);
      return response.data;
    } else if (res.code == 7001) {
      //暂无权限
      router.push("/401");
    } else {
      // 响应的信息为 error
      return response.data;
    }
  },
  (error) => {
    Message.error("网络故障");
    return Promise.reject(error);
  }
);

export default axios;

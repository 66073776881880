import Vue from "vue";
import VueRouter from "vue-router";
// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import("@/views/Index"),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import("@/views/Home"),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import("@/views/About"),
      },
      {
        path: 'business',
        name: 'business',
        component: () => import("@/views/Business"),
      },
      {
        path: 'platform',
        name: 'platform',
        component: () => import("@/views/Platform"),
      },
      {
        path: 'join',
        name: 'join',
        component: () => import("@/views/Join"),
      },
      {
        path: 'news',
        name: 'news',
        component: () => import("@/views/News"),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import("@/views/Contact"),
      },
      {
        path: 'newsDetail',
        name: 'newsDetail',
        component: () => import("@/views/NewsDetail"),
      }
    ]
  },

]

const router = new VueRouter({
  routes
})

export default router

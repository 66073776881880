import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/reset.scss";
import "./assets/css/global.scss";
import axios from "@/utils/axios.init";
import api from "@/utils/api";

Vue.config.productionTip = false;

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 50 };
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

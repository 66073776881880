const managerUrl = "/api"; // 认证层接口地址 正式环境

const api = {
  public: {
    getArea: `${managerUrl}/v6/system/area/children`, // 获取地区列表
  },
  //加入我们
  join: {
    add: `${managerUrl}/v6/website/join/add`,//加入我们
  },
  // 新闻
  news: {
    newsHot: `${managerUrl}/v6/website/news/hot`,//热门新闻
    newsList: `${managerUrl}/v6/website/news/list`,//全部新闻
    newsDetail: `${managerUrl}/v6/website/news/detail`, //新闻详情
  }
};

export default api;
